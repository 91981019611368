import { Button, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import NextLink from 'next/link'

import { routes } from 'routes'

import Logo from 'assets/Logo'
import { createLayout } from 'components/layouts/SiteLayout'
import SiteToolbar from 'components/layouts/SiteToolbar'

export default function NotFoundPage() {
  return (
    <Container maxWidth="sm">
      <SiteToolbar />
      <Box sx={{ p: 2, textAlign: 'center', bgcolor: 'white' }}>
        <Grid container justifyContent="center" sx={{ my: 3 }}>
          <Grid item xs={12} mb={3}>
            <Logo size={55} />
          </Grid>
          <Grid item sm={10} mb={3}>
            Page not found
          </Grid>
          <Grid item sm={10}>
            <NextLink href={routes.root} passHref>
              <Button size="small" disableElevation color="secondary" variant="contained">
                Home
              </Button>
            </NextLink>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

NotFoundPage.getLayout = createLayout('404 Page Not Found')
