import Box from '@mui/material/Box'
import { ReactNode } from 'react'

import AppHeader from './AppHeader'
import FullscreenContainer from './FullscreenContainer'
import PageMeta from './PageMeta'
import { LayoutProps } from './types'

export default function SiteLayout({ pageTitle, children, isFullscreen = false }: LayoutProps) {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <PageMeta pageTitle={pageTitle} />
      <AppHeader />
      {isFullscreen ? <FullscreenContainer>{children}</FullscreenContainer> : <>{children}</>}
    </Box>
  )
}

export const getLayout = (page: ReactNode) => <SiteLayout>{page}</SiteLayout>
export const createLayout = (pageTitle: string, isFullscreen?: boolean) => {
  // eslint-disable-next-line react/display-name
  return (page: ReactNode) => (
    <SiteLayout pageTitle={pageTitle} isFullscreen={isFullscreen}>
      {page}
    </SiteLayout>
  )
}
